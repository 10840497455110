import { useEffect } from 'react';

const MOUSEDOWN = 'mousedown';
const TOUCHSTART = 'touchstart';

type HandledEvents = [typeof MOUSEDOWN, typeof TOUCHSTART];
type HandledEventsType = HandledEvents[number];
type PossibleEvent = {
  [Type in HandledEventsType]: HTMLElementEventMap[Type];
}[HandledEventsType];
type Handler = (event: PossibleEvent) => void;

const currentDocument = typeof document !== 'undefined' ? document : undefined;
const events: HandledEvents = [MOUSEDOWN, TOUCHSTART];

export function useOnClickOutside(
  ref: React.RefObject<HTMLElement | null>,
  handler: Handler | null,
  { document = currentDocument } = {},
) {
  useEffect(() => {
    if (typeof document === 'undefined' || !handler) {
      return;
    }

    const listener = (event: PossibleEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    events.forEach(event => {
      document.addEventListener(event, listener);
    });

    return () => {
      events.forEach(event => {
        document.removeEventListener(event, listener);
      });
    };
  }, [ref, handler, document]);
}
