import slugify from 'slugify';

import { UNIVERSAL_ENV_VARS } from '../constants/environmentVariables';
import { getExploreUrl } from '../views/Explore/context/helpers';

import { getDatabaseEnv } from './environment/getDatabaseEnv';
import { getMykissEnv } from './environment/getMykissEnv';

const DOMAINS = [
  'https://testing.staging.fishbrain.com',
  'https://staging.fishbrain.com',
  'https://fishbrain.com',
];

const toBoolean = (value: any): boolean => {
  if (value === 'false') {
    return false;
  }

  return !!value;
};

export const getDomain = (): string => {
  switch (getMykissEnv()) {
    case 'production':
      return 'https://fishbrain.com';
    case 'staging':
      if (toBoolean(UNIVERSAL_ENV_VARS.IS_TESTING_DOMAIN)) {
        return 'https://testing.staging.fishbrain.com';
      }
      return 'https://staging.fishbrain.com';
    default:
      return 'http://localhost:3002';
  }
};

export const getAdminDbDomain = (): string => {
  if (getMykissEnv() === 'production') {
    return 'https://ornatus.fishbrain.com';
  }
  return 'https://ornatus.staging.fishbrain.com';
};

export const getCognitoDomain = (): string => {
  switch (getDatabaseEnv()) {
    case 'production':
      return 'https://accounts.fishbrain.com';
    default:
      return 'https://accounts.staging.fishbrain.com';
  }
};

// This assumes the url starts with a protocol.
export function isExternalDomain(url: string): boolean {
  return url.startsWith('http') && !DOMAINS.some(domain => url.startsWith(domain));
}

export function stripFishbrainDomain(url: string): string {
  return DOMAINS.reduce((strippedUrl, domain) => strippedUrl.replace(domain, ''), url);
}

export const safeSlugify = (string: string): string => {
  const slug = slugify(string, { lower: true, strict: true, remove: /[*+~.()'"!:@/]/g });
  return slug.length ? slug : string;
};

export const getUrlHostname = (url: string): string => {
  try {
    const structuredUrl = new URL(url);
    return structuredUrl.hostname;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return '';
  }
};

// Country / region / city
export const getCountryUrl = (id: string, name: string): string =>
  `/countries/${id.toLowerCase()}-${safeSlugify(name)}`;

export const getRegionUrl = (
  countryId: string,
  regionId: string,
  countryName: string,
  regionName: string,
): string =>
  `/countries/${countryId}-${safeSlugify(countryName)}/regions/${regionId}-${safeSlugify(regionName)}`.toLowerCase();

export const getPlaceUrl = (countryId: string, regionCode: string, slug: string): string =>
  `/cities/${countryId}/${regionCode}/${slug}`.toLowerCase();

export const getFishingWaterUrl = (externalId: string, name?: string | null): string =>
  name
    ? `/fishing-waters/${externalId}/${encodeURIComponent(safeSlugify(name))}`
    : `/fishing-waters/${externalId}`;

export const getPostUrl = (externalId: string): string => `/posts/${externalId}`;

export const getSpeciesUrl = (speciesId: string, name?: string | null): string =>
  `/species/${speciesId}${name ? `/${safeSlugify(name)}` : ''}`;

export const getPageUrl = (pageId: string, name?: string): string =>
  name ? `/pages/${pageId}/${safeSlugify(name)}` : `/pages/${pageId}`;

export const getPageAdminUrl = (pageId: string): string => `/me/pages/${pageId}`;

export const getPageAnalyticsUrl = (pageId: string): string => `/me/pages/${pageId}/analytics`;

export const pathToUrl = (path: string): string => `${getDomain()}${path}`;

export const getUserUrl = (nickame: string): string => `/anglers/${nickame}`;

export const getProUrl = (couponCode?: string): string =>
  couponCode ? `/pro/${couponCode}` : '/pro';

export const getMapWithFishrulesSpeciesUrl = (
  lat: number,
  lng: number,
  isMobile: boolean,
  fishrulesSpeciesId?: number,
): string => {
  if (!fishrulesSpeciesId) {
    if (isMobile) {
      return getExploreUrl({ latitude: lat, longitude: lng, drawerView: undefined });
    }
    return getExploreUrl({ latitude: lat, longitude: lng, drawerView: 'regulations' });
  }
  if (isMobile) {
    return getExploreUrl({
      latitude: lat,
      longitude: lng,
      drawerView: undefined,
      drawerViewId: fishrulesSpeciesId.toString(),
    });
  }
  return getExploreUrl({
    latitude: lat,
    longitude: lng,
    drawerView: 'regulation',
    drawerViewId: fishrulesSpeciesId.toString(),
  });
};

export const getBlogPostsUrl = (oneIndexedPage?: number, paginationPath?: string): string => {
  if (oneIndexedPage === 1) {
    return paginationPath || '/blog';
  }
  if (paginationPath) {
    return oneIndexedPage ? `${paginationPath}/page/${oneIndexedPage}` : paginationPath;
  }
  return oneIndexedPage ? `/blog/page/${oneIndexedPage}` : '/blog';
};

export const getBlogPostUrl = (categorySlug: string, postSlug: string): string =>
  `/blog/${categorySlug}/${postSlug}`;

export const getSpeciesBlogPostsUrl = (speciesSlug: string): string =>
  `/blog/species/${speciesSlug}`;

export const getAuthorBlogPostsUrl = (authorSlug: string): string => `/blog/authors/${authorSlug}`;

export function splitIdAndSlug(idAndSlug: string): string {
  const parts = idAndSlug.split('-');
  return parts[0];
}

export const getTripUrl = (id: string): string => `/trips/${id}`;

export const getSuggestWaterChangesUrl = (waterId: string, waterName: string): string =>
  `/suggest-water-changes?waterId=${waterId}&waterName=${encodeURIComponent(waterName)}`;
