export const geolocationPermissisonGranted = async (): Promise<boolean> => {
  try {
    if (navigator && navigator.permissions) {
      const permission = await navigator.permissions.query({ name: 'geolocation' });
      return permission.state === 'granted';
    }
    return false;
  } catch {
    return false;
  }
};
