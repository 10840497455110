import React from 'react';

import { useUserInfo } from '../../../../../helpers/hooks/useUserInfo';
import { AppStoreButtons } from '../../../../Clickables/Buttons/AppStoreButtons/AppStoreButtons';

import { HamburgerDropdownToggle } from './components/HamburgerDropdownToggle';
import { LogOutButton } from './components/LogOutButton';
import { PrimaryDropDownLinks } from './components/PrimaryDropDownLinks';
import { SecondaryDropDownLinks } from './components/SecondaryDropDownLinks';
import { TopCTAs } from './components/TopCTAs';
import { UserDetailsSection } from './components/UserDetailsSection';
import { useOnClickOutside } from '../../../../../helpers/hooks/useOnClickOutside';

interface IProps {
  expanded: boolean;
  onExpandedChange: (value: boolean) => void;
}

export const DropDownMenu = ({ expanded, onExpandedChange }: IProps) => {
  const { isLoggedIn, user } = useUserInfo();

  const mobileMenuRef = React.useRef<HTMLDivElement>(null);
  useOnClickOutside(mobileMenuRef, () => (expanded ? onExpandedChange(false) : null));

  return (
    <div ref={mobileMenuRef} className="main-navigation-child">
      <HamburgerDropdownToggle expanded={expanded} onExpandedChange={onExpandedChange} />
      <div
        className={`absolute -right-6 top-[40px] h-screen max-h-[calc(100vh-var(--main-navigation-height-mobile))] w-screen origin-top-right divide-y divide-midnight overflow-y-auto bg-sea-foam py-0 transition-all md:-right-2 md:top-[53px] md:h-auto md:w-80 md:rounded-b-lg md:border-x md:border-b md:border-twilight md:py-6 ${expanded ? `visible z-nav scale-100 opacity-100` : 'z-0 hidden scale-[0.98] opacity-0'}`}
        data-testid="dropdown-child-elements"
      >
        <div className="m-0">
          {!isLoggedIn && <TopCTAs />}

          {!!user && <UserDetailsSection onExpandedChange={onExpandedChange} />}

          <PrimaryDropDownLinks onExpandedChange={onExpandedChange} />

          <SecondaryDropDownLinks onExpandedChange={onExpandedChange} />

          {isLoggedIn && <LogOutButton onExpandedChange={onExpandedChange} />}

          <AppStoreButtons
            className="my-4 ml-6 flex items-start sm:my-6 sm:ml-11 md:hidden"
            orientation="vertical"
            source="DropDownMenu"
          />
        </div>
      </div>
    </div>
  );
};
