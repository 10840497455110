import { serialize } from 'cookie';
import { IncomingMessage, ServerResponse } from 'http';
import Cookies from 'universal-cookie';

const COOKIES_NOT_INITIALISED_ERROR = 'Cookies not initialised';

let _cookies: Cookies;

export function initialiseCookies(cookiesObj?: Cookies): void {
  if (cookiesObj) {
    _cookies = cookiesObj;
  } else {
    _cookies = new Cookies();
  }
}

export function intialiseNextServerCookies(
  request: IncomingMessage,
  response: ServerResponse,
): void {
  const cookies = new Cookies(request.headers.cookie);
  initialiseCookies(cookies);

  cookies.addChangeListener(change => {
    if (response.headersSent) {
      return;
    }

    const existingSetCookie = response.getHeader('Set-Cookie') || [];
    const existingSetCookieArray = Array.isArray(existingSetCookie)
      ? existingSetCookie
      : [existingSetCookie as string];
    response.setHeader('Set-Cookie', [
      ...existingSetCookieArray,
      serialize(change.name, change.value, change.options),
    ]);
  });
}

export function getCookies(): Cookies {
  if (!_cookies) {
    throw new Error(COOKIES_NOT_INITIALISED_ERROR);
  }
  return _cookies;
}

export function hasCookies(): boolean {
  return !!_cookies;
}
