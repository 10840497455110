import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ClientAppLogic"] */ "/app/src/components/ClientAppLogic/ClientAppLogic.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalAppLayout"] */ "/app/src/components/Layouts/GlobalAppLayout.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/fishbrain.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/index.css");
;
import(/* webpackMode: "eager" */ "/app/src/styles/osano.css");
