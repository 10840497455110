'use client';

import { ReactNode, Suspense } from 'react';

import { Map } from 'mapbox-gl';

import { AnalyticsProvider } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { AuthProvider } from '../../contexts/AuthContext';
import { CookiePermissionsProvider } from '../../contexts/CookiePermissionsContext/CookiePermissionsContext';
import { GeolocationProvider } from '../../contexts/GeolocationContext/GeolocationContext';
import { SessionProvider } from '../../contexts/SessionContext';
import { _hasAccessToken } from '../../helpers/authentication/shared';
import { hasCookies, initialiseCookies } from '../../helpers/cookies';
import { getReactErrorBoundary } from '../../helpers/errorHandling';
import { IEvent } from '../../interfaces/events';
import { IPageMetadata } from '../../interfaces/nextjs';
import { ApolloWrapper } from '../ApolloWrapper/ApolloWrapper';
import { ClientSideInitializer } from '../ClientSideInitializer/ClientSideInitializer';
import { PageViewHandler } from '../PageViewHandler/PageViewHandler';
import { Facebook } from '../Scripts/Facebook/Facebook';
import { GoogleTagManager } from '../Scripts/GoogleTagManager/GoogleTagManager';
import { Hotjar } from '../Scripts/Hotjar/Hotjar';

/**
 * The ClientAppLogic component is responsible for rendering the
 * client-side logic of the application. It wraps the entire
 * application with various context providers and handles error boundaries.
 *
 * Can be wrapped by the LayoutWrapper which is a server component
 * or can be used without the layout as in the case of app pages.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {IPageMetadata} props.pageProps - The metadata of the page.
 * @param {ReactNode} [props.children] - The child components to be rendered.
 * @param {boolean} [props.blockDatadogRum=false] - Whether to block Datadog RUM.
 * @returns {JSX.Element} The rendered component.
 */

interface IProps {
  pageProps: IPageMetadata;
  children?: ReactNode;
  blockDatadogRum?: boolean;
}

declare global {
  interface Window {
    __APOLLO_STATE__: {};
    __BUILDER_APOLLO_STATE__: {};
    __BUILDER_IO_APOLLO_STATE__: {};
    __HAS_AUTH_TOKEN: boolean;
    __PENDING_EVENTS__: IEvent[];
    Cypress: unknown;
    dataLayer?: {}[];
    exploreMap?: Map; // todo FIB-42586 do we need this?
    fbq?: (...args: any) => void; // Facebook pixel
    gapi: any;
    gtag?: (...args: any) => void; // Google Tag Manager
    Helpshift?: (...args: any) => void; // SupportChat
    Osano?: any; // Cookie consent
  }
}

// Initialise cookies. Not in a useEffect hook as we need it before the first render
// NB: For server components this will not instantiate the cookies
if (!hasCookies()) {
  initialiseCookies();
}

const ErrorBoundary = getReactErrorBoundary();

export const ClientAppLogic = ({ pageProps, children, blockDatadogRum = false }: IProps) => {
  return (
    <ErrorBoundary>
      <GeolocationProvider>
        <CookiePermissionsProvider>
          <Suspense>
            <GoogleTagManager />
          </Suspense>
          <Hotjar />
          <Facebook />
          <ApolloWrapper>
            <Suspense>
              <AnalyticsProvider blockDatadogRum={blockDatadogRum} {...pageProps}>
                {/* We start off unauthenticated by default and let the authentication be updated on the client */}
                <AuthProvider isAuthenticated={_hasAccessToken()}>
                  <SessionProvider>
                    <ClientSideInitializer>
                      <PageViewHandler
                        _pageId={pageProps._pageId}
                        _page={pageProps._page}
                        _pageTitle={pageProps._pageTitle}
                      >
                        {children}
                      </PageViewHandler>
                    </ClientSideInitializer>
                  </SessionProvider>
                </AuthProvider>
              </AnalyticsProvider>
            </Suspense>
          </ApolloWrapper>
        </CookiePermissionsProvider>
      </GeolocationProvider>
    </ErrorBoundary>
  );
};
