import React, { useEffect, useState } from 'react';
import invariant from 'tiny-invariant';

import { getLocationData } from '../../api/mykiss/getLocationData';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { reportMykissError } from '../../helpers/errorHandling';
import { useHandleUnmount } from '../../helpers/hooks/useHandleUnmount';
import { geolocationPermissisonGranted } from '../../helpers/location/geolocationPermissionGranted';
import { requestLocation } from '../../helpers/location/requestLocation';
import { ICloudfrontHeaders, ICoordinates } from '../../interfaces/geo';

import { getCoordinatesFromLocationData } from './helpers/getCoordinatesFromLocationData';

type CoordsSource = 'geolocation_api' | 'ip' | 'default';
type Location = {
  coordinates: ICoordinates;
  countryCode?: string;
  regionCode?: string;
};
type State = { isLoading: boolean; location: Location | undefined };

const GeolocationContext = React.createContext<State>({
  isLoading: true,
  location: undefined,
});

async function getLocationFromBrowser(): Promise<ICoordinates | null> {
  const isGranted = await geolocationPermissisonGranted();
  if (isGranted) {
    return requestLocation();
  }
  return null;
}

const GeolocationProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [locationData, setLocationData] = useState<ICloudfrontHeaders | null>(null);
  const [{ coordinates, source }, setCoordinates] = useState<{
    coordinates: ICoordinates;
    source: CoordsSource;
  }>(() => getCoordinatesFromLocationData(locationData));
  const [isLoadingBrowserLocation, setIsLoadingBrowserLocation] = useState(true);
  const [isLoadingCDNLocation, setIsLoadingCDNLocation] = useState(true);
  const unmounted = useHandleUnmount();
  const isLoading = isLoadingBrowserLocation || isLoadingCDNLocation;

  useEffect(() => {
    if (isBrowser()) {
      getLocationData()
        .then(newLocationData => {
          if (!unmounted.current) {
            setLocationData(newLocationData);
          }
          if (source !== 'geolocation_api') {
            if (!unmounted.current) {
              setCoordinates(getCoordinatesFromLocationData(newLocationData));
            }
          }
          setIsLoadingCDNLocation(false);
        })
        .catch(error => {
          setIsLoadingCDNLocation(false);
          reportMykissError(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isBrowser()) {
      getLocationFromBrowser()
        .then(location => {
          if (location) {
            setCoordinates({ coordinates: location, source: 'geolocation_api' });
          }
          setIsLoadingBrowserLocation(false);
        })
        .catch(error => {
          setIsLoadingBrowserLocation(false);
          reportMykissError(error);
        });
    }
  }, []);

  const location = isLoading
    ? undefined
    : {
        coordinates,
        countryCode: locationData?.countryCode ?? undefined,
        regionCode: locationData?.regionCode ?? undefined,
      };

  return (
    <GeolocationContext.Provider value={{ isLoading, location }}>
      {children}
    </GeolocationContext.Provider>
  );
};

function useGeolocation(): State {
  const context = React.useContext(GeolocationContext);
  invariant(context !== null);

  return context;
}

export { useGeolocation, GeolocationProvider, GeolocationContext };
