'use client';

import { ApolloNextAppProvider, ApolloClient } from '@apollo/experimental-nextjs-app-support';

import { initializeGraphqlAppClient } from '../../api/graphql/initializeGraphqlAppClient';

// TODO: Add a description comment for this component

// have a function to create a client for you
function makeClient(): ApolloClient<any> {
  // use the `ApolloClient` from "@apollo/experimental-nextjs-app-support"
  return initializeGraphqlAppClient();
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children }: React.PropsWithChildren) {
  return <ApolloNextAppProvider makeClient={makeClient}>{children}</ApolloNextAppProvider>;
}
