import { ApolloLink, NormalizedCacheObject, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  SSRMultipartLink,
} from '@apollo/experimental-nextjs-app-support';

import { _getToken } from '../../helpers/authentication/authentication';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { isProduction } from '../../helpers/environment/isProduction';
import { buildGraphqlHeaders, GRAPHQL_URI, CORS_OPTIONS } from '../rest/shared';

import { cacheTypePolicies } from './cacheTypePolicies';
import { getRetryLink } from './shared';

/*
 * Getting the client and initializing it. We use this in the client and on the server.
 */

const httpLink = createHttpLink({
  uri: GRAPHQL_URI,
  ...CORS_OPTIONS,
});

const authLink = setContext(async (_, context) => {
  const { headers, getAuthToken } = context;
  // The ideal situation is that the getAuthToken function should be passed in the context (
  // coming from useAuth), as it allows for the user's auth state to be updated correctly when the
  // the token is found to be invalid. That said, it's not always possible to do, for example if the
  // query is coming from outside the React render tree. For this case we fallback to using
  // `_getToken`.
  const asyncHeaders = await buildGraphqlHeaders(getAuthToken || _getToken);
  return { headers: { ...asyncHeaders, ...headers } };
});

export const initializeGraphqlAppClient = (
  isServerComponent?: boolean,
): ApolloClient<NormalizedCacheObject> => {
  const link =
    !isServerComponent && !isBrowser()
      ? // link for SSR
        ApolloLink.from([
          new SSRMultipartLink({
            stripDefer: true,
          }),
          authLink.concat(getRetryLink('rutilus')).concat(httpLink),
        ])
      : // link for Client and Server Components
        authLink.concat(getRetryLink('rutilus')).concat(httpLink);

  return new ApolloClient({
    connectToDevTools: !isProduction() && isBrowser(),
    cache: new InMemoryCache(cacheTypePolicies),
    link,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  });
};
