import NextImage from 'next/image';

import { isBrowser } from '../../helpers/environment/isBrowser';
import { AppStoreButtons } from '../Clickables/Buttons/AppStoreButtons/AppStoreButtons';
import { ExternalLink } from '../Clickables/ExternalLink/ExternalLink';
import { InternalLink } from '../Clickables/InternalLink/InternalLink';
import { EmailIcon } from '../Icons/SocialMediaIcons/EmailIcon';
import { FacebookIcon } from '../Icons/SocialMediaIcons/FacebookIcon';
import { InstagramIcon } from '../Icons/SocialMediaIcons/InstagramIcon';
import { LinkedInIcon } from '../Icons/SocialMediaIcons/LinkedInIcon';
import { TwitterIcon } from '../Icons/SocialMediaIcons/TwitterIcon';
import { YouTubeIcon } from '../Icons/SocialMediaIcons/YouTubeIcon';
import { Text } from '../Text/Text';

import styles from './Footer.module.css';
import fishbrainLogoAndText from './White_Mono_Panorama.svg';
import { Container } from '../Container/Container';
import { Button } from '../Clickables/Buttons/Button';

export const Footer = () => {
  return (
    <>
      <footer className="-mt-px flex flex-col bg-deep-sea text-white" data-testid="Footer">
        <Container className="flex flex-col lg:flex-row lg:justify-between">
          <div className="md:w-2/3">
            <NextImage
              alt="Fishbrain Page"
              title="Fishbrain Page"
              width={200}
              height={48}
              className="mt-md mb-sm h-12"
              src={fishbrainLogoAndText}
            />
            <div
              className={`mb-10 flex flex-col justify-end sm:flex-row sm:items-start sm:justify-between lg:w-2/3 ${styles.footerBottom}`}
            >
              <ul>
                <li>
                  <InternalLink
                    to="/mission"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    About
                  </InternalLink>
                </li>
                <li>
                  <ExternalLink
                    href="https://careers.fishbrain.com/"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Careers
                  </ExternalLink>
                </li>
                <li>
                  <ExternalLink
                    href="https://fishbrain.helpshift.com/a/fishbrain---social-fishing-forecast-app/"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Support
                  </ExternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/investors"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Investors
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/advertise"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Advertise
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/privacy"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Privacy policy
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/terms-of-service"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Terms of service
                  </InternalLink>
                </li>
                <li>
                  <ExternalLink
                    href="https://fishbrain.whistlelink.com/"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Whistleblowing
                  </ExternalLink>
                </li>
              </ul>

              <ul>
                <li>
                  <InternalLink to="/brands" variant="textDeprecatedLight" className="body-text-md">
                    Brands
                  </InternalLink>
                </li>
                <li>
                  <InternalLink to="/blog" variant="textDeprecatedLight" className="body-text-md">
                    Blog
                  </InternalLink>
                </li>
                <li>
                  <InternalLink to="/knots" variant="textDeprecatedLight" className="body-text-md">
                    Knots
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/fishing-waters/popular"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Popular waters
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/security"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Bug bounty
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/cookie-policy"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Cookie policy
                  </InternalLink>
                </li>
                <li>
                  <Button
                    aria-label="cookie-preferences"
                    onClick={() => {
                      if (isBrowser() && window.Osano !== undefined) {
                        window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
                      }
                    }}
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Cookie Preferences
                  </Button>
                </li>
              </ul>
              <ul>
                <li>
                  <InternalLink to="/pro" variant="textDeprecatedLight" className="body-text-md">
                    Fishbrain Pro
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Features
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features/fishing-forecasts"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Forecasts
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features/fish-identifier"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Fish Identifier
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features/fishing-spots"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Fishing spots
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features/depth-maps"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Depth maps
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features/logbook"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Logbook
                  </InternalLink>
                </li>
                <li>
                  <InternalLink
                    to="/features/waypoints"
                    variant="textDeprecatedLight"
                    className="body-text-md"
                  >
                    Waypoints
                  </InternalLink>
                </li>
              </ul>
            </div>
          </div>

          <div className="mb-2 flex flex-col items-start lg:w-1/3 lg:items-end lg:justify-end">
            <div className="mb-4 flex flex-col lg:items-end">
              <Text className="mb-0">263 Shuman Blvd</Text>
              <Text className="mb-0">Suite 145 Naperville</Text>
              <Text>IL 60563</Text>
            </div>

            <div className={`mb-6 flex flex-row justify-start ${styles.socialMediaBadges}`}>
              <ExternalLink href="https://www.facebook.com/FishBrainApp/?fref=ts">
                <span className="hidden">Facebook</span>
                <FacebookIcon title="Facebook" width={22} height={22} />
              </ExternalLink>
              <ExternalLink href="http://instagram.com/fishbrainapp">
                <span className="hidden">Instagram</span>
                <InstagramIcon title="Instagram" width={22} height={22} />
              </ExternalLink>
              <ExternalLink href="https://www.linkedin.com/company/fishbrain-ab/">
                <span className="hidden">LinkedIn</span>
                <LinkedInIcon title="LinkedIn" width={22} height={22} />
              </ExternalLink>
              <ExternalLink href="https://twitter.com/fishbrainapp">
                <span className="hidden">Twitter</span>
                <TwitterIcon title="Twitter" width={22} height={22} />
              </ExternalLink>
              <ExternalLink href="https://www.youtube.com/channel/UCal2VSWDhTfMrBQ81wziXLw">
                <span className="hidden">Youtube</span>
                <YouTubeIcon title="YouTube" width={22} height={22} />
              </ExternalLink>
              <a href="mailto:support@fishbrain.com">
                <span className="hidden">Email</span>
                <EmailIcon title="Email" width={22} height={22} />
              </a>
            </div>

            <AppStoreButtons source="Footer" />
          </div>
        </Container>
        <div className={`${styles.footerWave} h-52 w-auto bg-cover bg-left-bottom`} />
      </footer>
    </>
  );
};
