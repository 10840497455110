'use client';

import React from 'react';

import dynamic from 'next/dynamic';
import { useSearchParams, useRouter, usePathname } from 'next/navigation';
import { ToastContainer } from 'react-toastify';

import { TOAST_CONFIG } from '../../constants/constants';
import { useClientHasRendered } from '../../helpers/hooks';
import { ConsentStatus, useConsent } from '../../helpers/hooks/useConsent';
import { useUserInfo } from '../../helpers/hooks/useUserInfo';
import { objectToQueryString, queryStringToObject } from '../../helpers/url/queryUtils';
import { URLParameters } from '../../views/Onboarding/constants/url/urlParameters';
import { ConsentModal } from '../ConsentModal/ConsentModal';
import { Footer } from '../Footer/Footer';
import { Modal } from '../Modal/Modal';
import { ModalHeader } from '../Modal/ModalHeader';
import { MainNavigation } from '../Navigation/MainNavigation/MainNavigation';
import { ISiteSubNavLinkItem } from '../Navigation/SiteSubNavigation/helpers/siteSubNavLinks';
import { SiteSubNavigation } from '../Navigation/SiteSubNavigation/SiteSubNavigation';
import { SupportChat } from '../SupportChat/SupportChat';
import { StickyBannerGetApp } from '../StickyBottomBanner/StickyBannerGetApp/StickyBannerGetApp';

const DynamicAuthentication = dynamic(async () =>
  import('../../views/Authentication/Authentication').then(mod => mod.Authentication),
);

const DynamicOnboarding = dynamic(async () =>
  import('../../views/Onboarding/Onboarding').then(mod => mod.Onboarding),
);

const DynamicPostFormPage = dynamic(async () =>
  import('../PostForm/PostFormPage').then(mod => mod.PostFormPage),
);

// todo update stickybottombanner

export const GlobalAppLayout = ({
  children,
  hasStickyBottomBanner = false,
  hideStickyBannerGetApp = false,
  hideFooter = false,
  disableSupportChat = false,
  siteSubNavigationLinks = undefined,
  stickyBannerGetAppDescription,
}: {
  children: any;
  hideStickyBannerGetApp?: boolean;
  hasStickyBottomBanner?: boolean;
  hideFooter?: boolean;
  disableSupportChat?: boolean;
  siteSubNavigationLinks?: ISiteSubNavLinkItem[];
  stickyBannerGetAppDescription?: string;
}) => {
  const pathname = usePathname();
  const queryParams = useSearchParams();
  const { replace } = useRouter();

  const clientHasRendered = useClientHasRendered();
  const { isLoggedIn } = useUserInfo();
  const [modalRenderingDelay, setModalRenderingDelay] = React.useState(true);
  const { consentStatus, acceptConsent } = useConsent();

  const initialFlow = queryParams?.get(URLParameters.Flow) || null;

  React.useEffect(() => {
    setTimeout(() => {
      setModalRenderingDelay(false);
    }, 1);
  });

  const postModalVisible = queryParams?.get('add_post') && isLoggedIn;

  const closeModal = (): void => {
    // remove add_post from route to close the modal
    const { add_post, ...rest } = queryStringToObject(queryParams?.toString());
    const url = `${pathname}${objectToQueryString(rest) ? `?${objectToQueryString(rest)}` : ''}`;
    replace(url);
  };

  return (
    <>
      {clientHasRendered && (
        <>
          <SupportChat
            hasStickyBottomBanner={hasStickyBottomBanner || !hideStickyBannerGetApp}
            disableSupportChat={disableSupportChat}
          />
        </>
      )}

      {clientHasRendered && (
        <ToastContainer
          icon={false}
          theme="light"
          position={TOAST_CONFIG.position}
          autoClose={TOAST_CONFIG.autoClose}
        />
      )}
      {/* // On same pages portal rendering is too eager and renders visible, to fix this a very small delay is introduced. */}
      {!modalRenderingDelay && postModalVisible && (
        <Modal
          isOpen={postModalVisible}
          onClose={closeModal}
          disableClose={{
            disableClickOutside: true,
            disableCloseButton: false,
            disableEscapeKey: true,
          }}
          size="lg"
          style={{ height: '80vh' }}
        >
          <ModalHeader title="Post to Fishbrain" />
          <DynamicPostFormPage />
        </Modal>
      )}
      {!modalRenderingDelay &&
        consentStatus !== ConsentStatus.NotLoggedIn &&
        consentStatus !== ConsentStatus.Accepted && (
          <ConsentModal onAccepted={acceptConsent} consentStatus={consentStatus} />
        )}
      <div className="bg-white">
        {clientHasRendered &&
          queryParams &&
          !queryParams.has(URLParameters.Company) &&
          queryParams.get(URLParameters.Flow) && (
            <DynamicAuthentication initialFlow={initialFlow} />
          )}
        {clientHasRendered &&
          queryParams &&
          queryParams.has(URLParameters.Company) &&
          queryParams.get(URLParameters.Flow) && <DynamicOnboarding />}
        <MainNavigation />
        {siteSubNavigationLinks && <SiteSubNavigation navItems={siteSubNavigationLinks} />}
        {children}
        {!hideStickyBannerGetApp && (
          <StickyBannerGetApp description={stickyBannerGetAppDescription} />
        )}
        {!hideFooter && <Footer />}
      </div>
    </>
  );
};
