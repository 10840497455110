import { twMerge } from 'tailwind-merge';

import { useAnalytics } from '../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../helpers/analytics/trackEvent';
import { useClientHasRendered } from '../../../../helpers/hooks';
import { useIsAndroid } from '../../../../helpers/hooks/useIsAndroid';
import { useIsIOS } from '../../../../helpers/hooks/useIsIos';
import { useIsMobileDevice } from '../../../../helpers/hooks/useIsMobileDevice';
import {
  AppDownloadUrlSourceType,
  getAppDownloadUrl,
} from '../../../../helpers/url/getAppDownloadUrl';
import { EventMykissCtaButtonClicked } from '../../../../interfaces/events';
import { ExternalLink } from '../../ExternalLink/ExternalLink';

import appstoreImg from './apple_app_store.svg';
import { AppStoreButtonsLoader } from './components/AppStoreButtonsLoader';
import googlePlayImg from './google-play-badge.png';
interface IProps {
  className?: string;
  context?: string;
  orientation?: 'horizontal' | 'vertical';
  /* A unique id for the location where the AppStoreButtons are used is, e.g. "waters_sidebar", "blog_post_footer" */
  source: EventMykissCtaButtonClicked['properties']['source'];
  /* If the source is a datatype (e.g. a water, a species), then this is the external ID of that datatype */
  sourceId?: EventMykissCtaButtonClicked['properties']['source_id'];
  customDeepLink?: string;
  badgeWidth?: number;
  badgeHeight?: number;
}

const APPLE_ALT = 'Download on the App Store';
const ANDROID_ALT = 'Get it on Google Play';

const BADGE_WIDTH = 160;
const BADGE_HEIGHT = 54;

export const AppStoreButtons = ({
  className,
  context,
  orientation = 'horizontal',
  source,
  sourceId,
  customDeepLink,
  badgeWidth = BADGE_WIDTH,
  badgeHeight = BADGE_HEIGHT,
}: IProps) => {
  const clientHasRendered = useClientHasRendered();
  const isMobileDevice = useIsMobileDevice();
  const isAndroid = useIsAndroid();
  const isIOS = useIsIOS();
  const { currentPage } = useAnalytics();

  const spacingClass =
    orientation === 'vertical' ? 'justify-center' : 'justify-start gap-x-3 md:gap-x-4';
  const linkClass = 'flex items-center justify-center bg-contain bg-center bg-no-repeat';

  const androidUrl =
    customDeepLink ||
    getAppDownloadUrl({
      appDownloadSourceType: AppDownloadUrlSourceType.GooglePlayButton,
      appDownloadSource: source,
      currentPageId: currentPage,
      osName: 'Android',
    });
  const iosUrl =
    customDeepLink ||
    getAppDownloadUrl({
      appDownloadSourceType: AppDownloadUrlSourceType.AppStoreButton,
      appDownloadSource: source,
      currentPageId: currentPage,
      osName: 'iOS',
    });

  const onClick = (): void => {
    void trackEvent({
      name: 'mykiss_cta_button_clicked',
      properties: {
        context: context,
        page: currentPage,
        source,
        type: 'download',
        source_id: sourceId,
      },
    });
  };

  return (
    <aside
      style={{
        width: orientation === 'vertical' ? `${badgeWidth}px` : 'auto',
        height: orientation === 'vertical' ? 'auto' : `${badgeHeight}px`,
      }}
      className={twMerge(`flex flex-wrap gap-y-3 ${spacingClass}`, className)}
      data-testid="AppStoreButtons"
    >
      {clientHasRendered ? (
        <>
          {isMobileDevice && isIOS && (
            <ExternalLink
              style={{
                height: `${badgeHeight}px`,
                width: `${badgeWidth}px`,
                backgroundImage: `url(${appstoreImg.src})`,
              }}
              href={iosUrl}
              className={linkClass}
              onClick={onClick}
            >
              <span className="sr-only">{APPLE_ALT}</span>
            </ExternalLink>
          )}
          {isMobileDevice && isAndroid && (
            <ExternalLink
              style={{
                height: `${badgeHeight}px`,
                width: `${badgeWidth}px`,
                backgroundImage: `url(${googlePlayImg.src})`,
              }}
              href={androidUrl}
              className={linkClass}
              onClick={onClick}
            >
              <span className="sr-only">{ANDROID_ALT}</span>
            </ExternalLink>
          )}
          {(!isMobileDevice || (!isAndroid && !isIOS)) && (
            <>
              <ExternalLink
                style={{
                  height: `${badgeHeight}px`,
                  width: `${badgeWidth}px`,
                  backgroundImage: `url(${appstoreImg.src})`,
                }}
                href={iosUrl}
                className={linkClass}
                onClick={onClick}
              >
                <span className="sr-only">{APPLE_ALT}</span>
              </ExternalLink>
              <ExternalLink
                style={{
                  height: `${badgeHeight}px`,
                  width: `${badgeWidth}px`,
                  backgroundImage: `url(${googlePlayImg.src})`,
                }}
                href={androidUrl}
                className={linkClass}
                onClick={onClick}
              >
                <span className="sr-only">{ANDROID_ALT}</span>
              </ExternalLink>
            </>
          )}
        </>
      ) : (
        <AppStoreButtonsLoader
          badgeWidth={badgeWidth}
          badgeHeight={badgeHeight}
          orientation={orientation}
        />
      )}
    </aside>
  );
};
