// NOTE: This file is separated from authentication.ts as in needs to run under the NextJS Middleware
// runtime which has a restricted API - in particular we can't currently use Bugsnag here. Please
// be cautious if importing new files/modules here and test NextJS server renders.
import { CookieSetOptions } from 'universal-cookie';

import { getCookies } from '../cookies';

const MAX_COOKIE_LENGTH_IN_DAYS = 180;
const REFRESH_BUFFER_IN_SECONDS = 120;
export const ACCESS_TOKEN_KEY = 'web-auth-v2-access-token';
export const REFRESH_TOKEN_KEY = 'web-auth-v2-refresh-token';
const ID_TOKEN_KEY = 'web-auth-v2-id-token';

const MS_IN_A_SECOND = 1000;
const dateFromSeconds = (seconds: number): Date => new Date(seconds * MS_IN_A_SECOND);

function getDefaultCookieOptions(): { path: string; expires: Date } {
  const cookieExpiryDate = new Date();
  cookieExpiryDate.setDate(cookieExpiryDate.getDate() + MAX_COOKIE_LENGTH_IN_DAYS);
  return {
    path: '/',
    expires: cookieExpiryDate,
  };
}

export function getCookie(key: string): string {
  return getCookies().get(key) || '';
}

export function setCookie(key: string, value: string, cookieOptions: CookieSetOptions): void {
  getCookies().set(key, value, cookieOptions);
}

function removeCookie(key: string): void {
  getCookies().remove(key, getDefaultCookieOptions());
}

export function getIdToken(): string {
  return getCookie(ID_TOKEN_KEY);
}

export function _destroyAccessToken(): void {
  removeCookie(ACCESS_TOKEN_KEY);
}

export function destroyTokens(): void {
  removeCookie(ACCESS_TOKEN_KEY);
  removeCookie(REFRESH_TOKEN_KEY);
  removeCookie(ID_TOKEN_KEY);
}

export function _getRefreshToken(): string {
  return getCookie(REFRESH_TOKEN_KEY);
}

export function hasRefreshToken(): boolean {
  const refreshToken = _getRefreshToken();
  return typeof refreshToken === 'string' && refreshToken !== '' && refreshToken !== 'undefined';
}

export function _setRefreshToken(refreshToken: string): void {
  setCookie(REFRESH_TOKEN_KEY, refreshToken, {
    ...getDefaultCookieOptions(),
  });
}

/**
 * Although this is exported, it is only intended for use with AuthContext and should not be directly
 * used!
 */
export function _getAccessToken(): string {
  return getCookie(ACCESS_TOKEN_KEY);
}

// This is only exported for use when initially loading the app as our middleware will have refreshed
// any invalid access tokens.
export function _hasAccessToken(): boolean {
  return (
    typeof _getAccessToken() === 'string' &&
    _getAccessToken() !== '' &&
    _getAccessToken() !== 'undefined'
  );
}

export function _setAccessToken(accessToken: string, expiryDateInSeconds: number): void {
  setCookie(ACCESS_TOKEN_KEY, accessToken, {
    ...getDefaultCookieOptions(),
    // We set the cookie expiry a little earlier to avoid race conditions
    expires: dateFromSeconds(expiryDateInSeconds - REFRESH_BUFFER_IN_SECONDS),
  });
}

export function _setIdToken(idToken: string): void {
  setCookie(ID_TOKEN_KEY, idToken, {
    ...getDefaultCookieOptions(),
  });
}
